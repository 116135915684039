import React, { Component } from "react"
import { Video, Transformation } from "cloudinary-react"

class CloudinaryVideo extends Component {
  constructor(props) {
    super(props)
    let publicId
    if (props.publicUrl && props.publicUrl != "") {
      let urlSplit = props.publicUrl.split("/")
      publicId = `${urlSplit[urlSplit.length - 2]}/${
        urlSplit[urlSplit.length - 1]
      }`
      publicId = publicId.replace(/\.[^/.]+$/, "")
    }
    this.state = {
      videoStarted: false,
      publicId: publicId,
    }

    this.cloudinaryVidRef = React.createRef()
    this.videoPoster = React.createRef()
    this.playVideo = this.playVideo.bind(this)
    this.pauseVideo = this.pauseVideo.bind(this)
    this.startVideo = this.startVideo.bind(this)
  }
  playVideo() {
    this.cloudinaryVidRef.current.play()
  }
  pauseVideo() {
    this.cloudinaryVidRef.current.pause()
  }
  startVideo() {
    if (this.cloudinaryVidRef.current.paused) {
      this.cloudinaryVidRef.current.play()
      this.videoPoster.current.style.opacity = "0"
    } else {
      this.cloudinaryVidRef.current.pause()
      this.videoPoster.current.style.opacity = "1"
    }
  }

  render() {
    return (
      <>
        <Video
          secure
          playsInline
          className={this.props.class_list}
          innerRef={this.cloudinaryVidRef}
          autoPlay={this.props.autoPlay === false ? false : true}
          loop={true}
          muted={true}
          cloudName="dxjbr8hie"
          publicId={this.state.publicId}
        >
          {!this.props.disableGif ? (
            <Transformation flags={["awebp", "animated"]} />
          ) : (
            ""
          )}
          {this.props.boomerangEffect ? (
            <Transformation effect="boomerang" />
          ) : (
            ""
          )}
          {this.props.fadeInOut ? <Transformation effect="fade:250" /> : ""}
          {this.props.fadeInOut || this.props.fadeOut ? (
            <Transformation effect="fade:-250" />
          ) : (
            ""
          )}
        </Video>
      </>
    )
  }
}

export default CloudinaryVideo
