import React from 'react'
import Header from "../components/header"
// import Cursor from "../components/cursor"

export default ({ children }) => (
  <>
    <Header/>
    {children}
    <div id="mouse-circle"></div>
  </>
)
