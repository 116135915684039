import React from "react"
import { Link } from "gatsby"
import ArrowCircle from "../icons/arrow-circle.svg"

const ButtonGroup = ({ buttonarray, classes }) => {
  return (
    <div className={`button-group ${classes}`}>
      {buttonarray.map((button, index) => {
        return (
          <Link
            key={index}
            to={button.button.url}
            className={
              button.buttonType === "arrow"
                ? "underline hover:no-underline"
                : "flex items-center px-4 h-10 med:px-6 med:h-12 border-solid border border-current transition-colors hover:bg-black hover:text-white"
            }
          >
            {button.button.title}
            {button.buttonType === "arrow" ? (
              <ArrowCircle
                className={`inline-block ml-4 fill-current ${button.buttonColour}`}
              />
            ) : (
              ""
            )}
          </Link>
        )
      })}
    </div>
  )
}

export default ButtonGroup
