import React from "react"
import { Link } from "gatsby"

const TopLinks = ({ buttonarray, classes }) => {
  return (
    <div className={`top-links ${classes}`}>
      {buttonarray && buttonarray.map((button, index) => {
        return (
          <Link
            key={index}
            to={button.link.url}
            className="hover:underline"
          >
            {button.link.title}
          </Link>
        )
      })}
    </div>
  )
}

export default TopLinks
