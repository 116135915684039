/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.com/docs/browser-apis/
 */

import { deviceType } from "detect-it"

export const onClientEntry = () => {
  let vh = window.innerHeight * 0.01
  document.documentElement.style.setProperty("--vh", `${vh}px`)
  window.addEventListener("resize", () => {
    let vh = window.innerHeight * 0.01
    document.documentElement.style.setProperty("--vh", `${vh}px`)
  })
}

// normalize CSS across browsers
// import "./src/styles/normalize.css"
// custom CSS styles
// import "./src/styles/style.css"
// import { gsap, SlowMo } from '@gsap/shockingly'
// import { ScrollTrigger } from "@gsap/shockingly/ScrollTrigger"
// import React from "react"
// import Wrap from "./src/components/wrap"
//
//
// // import { isMobile, isTablet } from "react-device-detect"
//
export const onRouteUpdate = () => {
  let mousePosX = 0,
    mousePosY = 0,
    mouseCircle = document.getElementById("mouse-circle")

  let delay = 1,
  revisedMousePosX = 0,
  revisedMousePosY = 0

//   if (deviceType === "mouseOnly") {
//     document
//       .querySelectorAll(
//         ".nav, .bg-tide, .bg-black, .bg-darkgreen, .btn-black-hover"
//       )
//       .forEach(el => {
//         el.addEventListener("mouseover", () => {
//           mouseCircle.classList.add("white")
//         })

//         el.addEventListener("mouseout", () => {
//           mouseCircle.classList.remove("white")
//         })
//       })

//     document.onmousemove = e => {
//       mousePosX = e.clientX
//       mousePosY = e.clientY
//     }

//     delayMouseFollow()
//   }

//   function delayMouseFollow() {
//     requestAnimationFrame(delayMouseFollow)

//     revisedMousePosX += (mousePosX - revisedMousePosX) / delay
//     revisedMousePosY += (mousePosY - revisedMousePosY) / delay

//     mouseCircle.style.top = revisedMousePosY + "px"
//     mouseCircle.style.left = revisedMousePosX + "px"
//   }
}

// export const wrapPageElement = ({ element, props }) => {
//   // props provide same data to Layout as Page element will get
//   // including location, data, etc - you don't need to pass it
//   return <Wrap {...props}>{element}</Wrap>
// }

export const shouldUpdateScroll = ({
  routerProps: { location },
  getSavedScrollPosition,
}) => {
  let transitionDelay = 1
  if (location.action === "PUSH") {
    window.setTimeout(() => window.scrollTo(0, 0), transitionDelay)
  } else {
    const savedPosition = getSavedScrollPosition(location)
    window.setTimeout(
      () => window.scrollTo(...(savedPosition || [0, 0])),
      transitionDelay
    )
  }
  return false
}
