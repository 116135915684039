import React, { useState, useEffect } from "react"
import { globalHistory } from "@reach/router"
import { useStaticQuery, graphql, Link } from "gatsby"
import StandbyLogo from "../icons/standby-logo.svg"
import ButtonGroup from "./buttongroup"
import TopLinks from "./toplinks"
import Headroom from "headroom.js"
import NavLink from "./navlink"
import { motion } from "framer-motion"

const animVariants = {
  open: {
    opacity: 1,
    display: "block",
  },
  closed: {
    opacity: 0,
    transition: { delay: 0.5 },
    transitionEnd: {
      display: "none",
    },
  },
}

const navLinkVariants = {
  open: i => ({
    opacity: 1,
    y: 0,
    transition: { delay: i * 0.1, type: "Spring", stiffness: 100 },
  }),
  closed: {
    opacity: 0,
    y: 15,
  },
}

// class Header extends Component {
const Header = () => {
  const [isOpen, setIsOpen] = useState(false)
  const [isHeaderHidden, setIsHeaderHidden] = useState(false)

  if (isOpen) {
    document.getElementById("mouse-circle").classList.add("white")
  }
  // Similar to componentDidMount and componentDidUpdate:
  useEffect(() => {
    // Update the document title using the browser API
    var headerEl = document.querySelector(".site-header")
    var headroom = new Headroom(headerEl, {
      classes: {
        initial: "site-header",
        pinned: "site-header--pinned",
        unpinned: "site-header--unpinned",
        top: "site-header--top",
        notTop: "site-header--not-top",
        bottom: "site-header--bottom",
        notBottom: "site-header--not-bottom",
        frozen: "site-header--frozen",
      },
    })
    // headroom.init();

    // globalHistory.listen(({ action }) => {
    //   if (action === 'PUSH') setIsHeaderHidden(true)
    // })
  })

  const { wp } = useStaticQuery(
    graphql`
      query {
        wp {
          globalOptions {
            globalSiteOptions {
              headerQuickLinks {
                link {
                  target
                  title
                  url
                }
              }
              headerButton {
                target
                title
                url
              }
              nav {
                link {
                  title
                  url
                  target
                }
                hoverVideo
                hoverImage {
                  localFile {
                    childImageSharp {
                      fluid {
                        ...GatsbyImageSharpFluid_withWebp
                      }
                    }
                  }
                }
                hideOnDesktop
              }
            }
          }
        }
      }
    `
  )

  const nav = wp.globalOptions.globalSiteOptions?.nav

  const quickLinks = wp.globalOptions.globalSiteOptions?.headerQuickLinks

  const headerButton = [
    {
      button: {
        ...wp.globalOptions.globalSiteOptions.headerButton,
      },
    },
  ]

  return (
    <>
      <header
        className={`site-header fixed top-0 left-0 w-screen py-6 z-30 transition-colors transition-transform transform ${
          isHeaderHidden && !isOpen ? "-translate-y-full" : ""
        } ${!isOpen ? "text-black delay-500" : "text-white"}`}
      >
        {!isOpen ? (
          <div className="absolute w-full h-full bg-white left-0 top-0 pointer-events-none"></div>
        ) : (
          ""
        )}
        <div className="container pr-0 note:pr-6">
          <div className="flex justify-between items-center relative desk:pl-6 pr-8">
            <Link
              className="flex logo-link"
              onClick={() => setIsOpen(false)}
              to="/"
            >
              <StandbyLogo className="fill-current" />
              <span className="hidden lrg:inline-block text-base">
                Standby Productions
              </span>
            </Link>
            <TopLinks
              classes={`hidden med:flex items-center gap-5 ml-auto med:mr-5`}
              buttonarray={quickLinks}
            />
            <ButtonGroup
              classes={`ml-auto med:ml-0 desk:mr-4 btn-black-hover`}
              buttonarray={headerButton}
            />
            <button
              onClick={() => setIsOpen(!isOpen)}
              className={`burger-button px-4 h-12 block appearance-none focus:outline-none`}
            >
              <span className="block"></span>
              <span className="block"></span>
              <span className="block"></span>
            </button>
          </div>
        </div>
      </header>

      <motion.nav
        className="nav fixed h-screen w-screen left-0 top-0 bg-black text-white z-40 hidden"
        animate={!isOpen ? "closed" : "open"}
        variants={animVariants}
      >
        <div className="w-full h-full overflow-auto">
          <div className="container pr-0 note:pr-6 flex flex-col min-h-full">
            <div className="flex justify-between items-center relative desk:pl-6 pr-2 desk:pr-0 py-6">
              <Link className="flex" onClick={() => setIsOpen(false)} to="/">
                <StandbyLogo className="fill-current" />
                <span className="hidden lrg:inline-block text-base">
                  Standby Productions
                </span>
              </Link>
              <button
                onClick={() => setIsOpen(!isOpen)}
                className={`bg-black px-4 h-12 flex justify-center items-center appearance-none focus:outline-none`}
              >
                <svg
                  className="w-6 h-6"
                  fill="none"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path d="M6 18L18 6M6 6l12 12"></path>
                </svg>
              </button>
            </div>

            <div className="flex flex-col justify-center lrg:pl-40 mt-auto mb-auto pb-20">
              {nav
                ? nav.map((navItem, index) => {
                    return (!navItem.hideOnDesktop || (navItem.hideOnDesktop && typeof window !== 'undefined' && window?.innerWidth < 750)) && (
                      <motion.div
                        onClick={() => setIsOpen(!isOpen)}
                        custom={index}
                        variants={navLinkVariants}
                        animate={isOpen ? "open" : "closed"}
                        key={index}
                      >

                        <NavLink
                          key={`nav-link-${index}`}
                          index={`nav-link-${index}`}
                          navItem={navItem}
                        />
                      </motion.div>
                    )
                    
                  })
                : ""}
            </div>
          </div>
        </div>
      </motion.nav>
    </>
  )
}
// transitionStatus === "exiting"
export default Header
